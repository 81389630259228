import React from "react";
import Context from "../assets/js/Context.js";
import axios from "axios";
import "@kenshooui/react-multi-select/dist/style.css"
import { Link } from 'react-router-dom';
import host from "../assets/js/Host";
import Component from "@reactions/component";
import Cookies from "universal-cookie";
import { Pane, Dialog } from 'evergreen-ui'

import { Form, Row, Col, Container, Card, Button, Table } from 'react-bootstrap';
import neworder from "../assets/img/neworder.jpeg";

import Search from "../assets/img/search.png";
import Check from "../assets/img/7.jpg";
import W from "../assets/img/w.jpg";
import Login from './login';
const cookies = new Cookies();
class Home extends React.Component {
    constructor() {
        super();
        this.displayDataAdt = [];
        this.displayDataAdt2 = [];
        this.state = {
            items: [],
            selectedItems: [],
            rejected_orders: 0,
            cancel_orders: 0,
            confirmed_orders: 0,
            watting_orders: 0,
            all_orders: 0,
            Percentage: 0,
        };

    }
    componentDidMount() {
        if (cookies.get("token")) {
            var header = { "Content-Type": "application/json", Authorization: cookies.get("token") };
            axios.get(host + `users/auth/profile`, { headers: header })
                .then((response) => {
                    this.setState({
                        rejected_orders: response.data.data.orders.rejected_orders,
                        cancel_orders: response.data.data.orders.cancel_orders,
                        confirmed_orders: response.data.data.orders.confirmed_orders,
                        watting_orders: response.data.data.orders.watting_orders,
                        all_orders: response.data.data.orders.all_orders,
                        Percentage: response.data.data.orders.confirmed_orders * response.data.data.profile.Percentage
                    })
                    // this.setState({ items: response.data.data.data })
                }).catch((error) => {
                    window.location.href = '/login';
                    console.log(error);
                })


        } 
        else {
            window.location.href = '/login';

        }

    }



    render() {

        return (
            <div>
                <Context.Consumer>
                    {ctx => {
                        if (cookies.get("token")) {
                            return (
                                <div>
                                    <div className="homeNav">
                                        <Button variant={'danger'} style={{ width: 100 }} onClick={() => {
                                            cookies.remove('token')
                                            window.location.href = '/login';
                                        }}>خروج</Button>
                                        <div>
                                            <Component initialState={{ isShown: false }}>
                                                {({ state, setState }) => (
                                                    <Pane>
                                                        <Dialog
                                                            isShown={state.isShown}
                                                            hasFooter={false}
                                                            title="تقارير الطلبات"
                                                            hasHeader={true}
                                                            intent="success"
                                                            onCloseComplete={() => setState({ isShown: false })}
                                                            confirmLabel="بحث"
                                                            cancelLabel='الغاء'
                                                            onConfirm={() => {
                                                                let number = document.getElementById('searchValue').value

                                                                window.location.href = `/search?number=${number.replace(/\s/g, '')}`;

                                                            }}
                                                        >
                                                            <div>
                                                                <Table striped bordered hover variant="dark" dir="rtl">

                                                                    <tbody>
                                                                        <tr>

                                                                            <td> الكلي </td>
                                                                            <td>{this.state.all_orders}</td>

                                                                        </tr>
                                                                        <tr>
                                                                            <td> الانتظار</td>
                                                                            <td>{this.state.watting_orders}</td>


                                                                        </tr>
                                                                        <tr>


                                                                        </tr>
                                                                        <tr>
                                                                            <td> الناحج</td>
                                                                            <td>{this.state.confirmed_orders}</td>


                                                                        </tr>
                                                                        <tr>
                                                                            <td> الراجع</td>
                                                                            <td>{this.state.rejected_orders}</td>


                                                                        </tr>
                                                                        <tr>
                                                                            <td> الملغي</td>
                                                                            <td>{this.state.cancel_orders}</td>


                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={2}>
                                                                                <div style={{ with: "100%", background: "#1E8449", textAlign: 'center' }}> النسبة المتوقعه: {this.state.Percentage}</div>

                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </Table>

                                                            </div>
                                                        </Dialog>

                                                        <Button onClick={() => setState({ isShown: true })} variant={'success'} style={{ width: 100 }}>معلومات</Button>

                                                        {/* <MdCancel style={{ color: '#d87373de', cursor: 'pointer', fontSize: 20 }}
                                                                        onClick={() => setState({ isShown: true })} /> */}
                                                    </Pane>
                                                )}
                                            </Component>
                                        </div>


                                    </div>
                                    <Container>

                                        <Row>
                                            <Col md={{ offset: 3 }}>
                                                <Card style={{ width: '100%' }}>
                                                    <Card.Img variant="top" src={neworder} />

                                                    <Card.Body>
                                                        <Link to='/FromUser'>
                                                            <center>   <Button variant="primary" style={{ width: 180, background: "#1F618D" }}>انشاء طلب جديد</Button> </center>
                                                        </Link>
                                                    </Card.Body>

                                                </Card>


                                            </Col>

                                        </Row>
                                        <br />

                                        <Row>
                                            <Col md={{ offset: 3 }}>
                                                <Card style={{ width: '100%' }}>
                                                    <Card.Img variant="top" src={Search} />

                                                    <Card.Body>
                                                        <Component initialState={{ isShown: false }}>
                                                            {({ state, setState }) => (
                                                                <Pane>
                                                                    <Dialog
                                                                        isShown={state.isShown}
                                                                        title=" ادخل رقم الوصل او رقم الهاتف"
                                                                        intent="success"
                                                                        onCloseComplete={() => setState({ isShown: false })}
                                                                        confirmLabel="بحث"
                                                                        cancelLabel='الغاء'
                                                                        onConfirm={() => {
                                                                            let number = document.getElementById('searchValue').value

                                                                            window.location.href = `/search?number=${number.replace(/\s/g, '')}`;

                                                                        }}
                                                                    >
                                                                        <Form.Group className="mb-3" dir="rtl">
                                                                            <Form.Label >رقم الوصل او رقم الهاتف</Form.Label>
                                                                            <Form.Control type="text" id="searchValue" placeholder="رقم الوصل او رقم الهاتف" />

                                                                        </Form.Group>
                                                                    </Dialog>
                                                                    <center> <Button onClick={() => setState({ isShown: true })} variant="primary" style={{ width: 180, background: "#1F618D" }}>بحث عن طلب</Button></center>
                                                                    {/* <MdCancel style={{ color: '#d87373de', cursor: 'pointer', fontSize: 20 }}
                                                                        onClick={() => setState({ isShown: true })} /> */}
                                                                </Pane>
                                                            )}
                                                        </Component>,
                                                        {/* <Link to='/search'>
                                                            <center>   <Button variant="primary" style={{ width: 180 }}>بحث عن طلب</Button> </center>
                                                        </Link> */}
                                                    </Card.Body>

                                                </Card>


                                            </Col>

                                        </Row>
                                        <br />
                                        <Row>
                                            <Col md={{ offset: 3 }}>
                                                <Card style={{ width: '100%' }}>
                                                    <Card.Img variant="top" src={W} />
                                                    <Card.Body>
                                                        <Link to='/Orders'>
                                                            <center>   <Button variant="primary" style={{ width: 180, background: "#1F618D" }}>طلبات قيد الانتظار</Button> </center>
                                                        </Link>
                                                    </Card.Body>
                                                </Card>


                                            </Col>

                                        </Row>
                                        <br />

                                        <br />
                                        <Row>
                                            <Col md={{ offset: 3 }}>
                                                <Card style={{ width: '100%' }}>
                                                    <Card.Img variant="top" src={Check} />
                                                    <Card.Body>
                                                        <Link to='/items'>
                                                            <center>   <Button variant="primary" style={{ width: 180, background: "#1F618D" }}>  المواد</Button> </center>
                                                        </Link>
                                                    </Card.Body>
                                                </Card>


                                            </Col>

                                        </Row>
                                        <br />

                                    </Container>
                                </div>
                            )
                        } else {
                            return (
                                <Login />
                            )
                        }

                    }}
                </Context.Consumer>
            </div>
        );
    }
}

export default Home;