import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import { Row, Col } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as moment from "moment-timezone";
import Login from '../common/login';
const cookies = new Cookies();
var colors = ['#2e8b57', '#6748b7', '#581845', '#900C3F', '#2B4C04', '#052E5A'
    , '#23056D', '#6B0759', '#6B0712', '#6B5707','#0A3BB3'
    , '#076E85', '#7D01A7', '#01A770', '#6C3693','#210C11'
];

class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            from: new Date(),
            to: new Date(),
            spin: false,
            serch: false,
            items_price_sum: '',
            items_dw_price_sum: '',
            items_saffe: '',
            orders_price: '',
            Spending_price: '',
            component_orders_count: '',
            orders_count: '',
            da5al: '',
            data: []
        }
    }
    Orders() {
    
        var date1 = moment(document.getElementById('date1').value, 'DD/MM/YYYY').format('MM/DD/YYYY');
        var date2 = moment(document.getElementById('date2').value, 'DD/MM/YYYY').format('MM/DD/YYYY');
        var header = { "Content-Type": "application/json", token: cookies.get("token") };
        axios.get(host + `dashbord/orders/reports/all/?from=${date1}&to=${date2}`, { headers: header })
            .then(res => {
                console.log(res.data.data.reports);
                // this.setState({
                //     spin: false,
                //     data: res.data.data.reports,
                // });
            })
            .catch(error => {
                console.log(error.response)
            });
    }
    handleChangeFrom = date => {
        console.log(date);

        this.setState({
            from: date
        });
    };
    handleChangeTo = date => {
        this.setState({
            to: date
        });
    };
    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        // if (this.state.spin) {
                        return (
                            <div >
                                <div id='ContinerReportDate'>
                                    <Row id='RowReportDate' style={{paddingTop:20}}>
                                        <Col id='ColReportDate' sm={12} md={4}>
                                            <div><span>من : &nbsp;</span></div>
                                         <div>
                                         <DatePicker id='date1'
                                                dateFormat="dd/MM/yyyy"
                                                selected={this.state.from}
                                                onChange={this.handleChangeFrom}
                                            />
                                         </div>
                                        </Col>
                                        <Col id='ColReportDate' sm={12} md={4}>
                                            <div><span>الى : &nbsp;</span></div>
                                        <div>
                                        <DatePicker id='date2'
                                                dateFormat="dd/MM/yyyy"
                                                selected={this.state.to}
                                                onChange={this.handleChangeTo}
                                            />
                                        </div>
                                        </Col>
                                        <Col id='ColReportDate' sm={12} md={4}>
                                            <div id='AddClientDeptBTN' onClick={() => {
                                                this.setState({ spin: true, serch: true })
                                                this.Orders()
                                            }}>بحث</div>
                                        </Col>
                                    </Row>
                                </div>
                                {!this.state.serch ? null : (
                                    <div>
                                        <Row style={{ direction: 'rtl', padding: 0, margin: 10, marginTop: 20 }}>
                                            {this.state.data.map((order, index) => (
                                                <Col id='colInfoOffice' sm={4} lg={4}>
                                                    <div id='CardInfoOfficeContiner' style={{ backgroundColor: colors[Math.floor(Math.random() * colors.length)] }}>
                                                    <h6><b> التاريخ : {order.data}</b> </h6>
                                                        <h6>عدد الطلبات الكلي:{order.watting + order.done + order.reject} طلب</h6>
                                                        <h6> المصاريف : {order.spending} </h6>
                                                    <hr className='reportsHr'></hr>
                                                      
                                                        <h6>المعلق: {order.watting} طلب</h6>

                                                        <h6>مبيعات المعلق : {order.watting_price} </h6>
                                                        <h6>اسعار مواد المعلق : {order.watting_dw.toFixed(2)} </h6>
                                                        <hr className='reportsHr'></hr>
                                                        <h6>الناجح: {order.done} طلب</h6>
                                                        <h6>مبيعات الناجح : {order.done_price} </h6>
                                                        <h6>اسعار مواد الناجح : {order.done_dw.toFixed(2)} </h6>
                                                        <hr className='reportsHr'></hr>
                                                        <h6>الراجع: {order.reject} طلب</h6>
                                                        <h6>مبيعات الراجع : {order.reject_price}</h6>
                                                        <h6>اسعار مواد الراجع : {order.reject_dw.toFixed(2)} </h6>
                                                        <hr className='reportsHr'></hr>
                                                        <h6> الصافي من الناجح : {((order.done_price-order.done_dw)-order.spending).toFixed(2)} </h6>
                                                    </div>
                                                </Col>
                                            ))}



                                        </Row>

                                    </div>
                                )}
                            </div>
                        )
                        // }
                        // else {
                        //     return (
                        //         <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        //             <Lottie
                        //                 options={{
                        //                     animationData: animation
                        //                 }}
                        //             />
                        //         </div>
                        //     )
                        // }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Category;