import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap';
import { toaster, Spinner } from 'evergreen-ui';
import Context from '../../assets/js/Context';
import axios from 'axios';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import Logo from "../../assets/img/logo.jpg"
// import Lottie from 'lottie-react-web'
// import animation from '../../assets/json/clean.json'
const cookies = new Cookies();

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      spinBtn: false,
    }
  }
  signIN(e) {

    e.preventDefault()
    axios.post(host + `dashbord/login`, {
      email: this.state.email,
      password: this.state.password,
    })
      .then(response => {
        console.log(response);
        cookies.set("token", response.data.data.token, { expires: new Date(Date.now() + 604800000) });
        localStorage.setItem('role',response.data.data.role)
        window.location.href = "/Cate2";
        this.setState({ spinBtn: false })
      })
      .catch(error => {
        if (error.response) {
          this.setState({ spinBtn: false })
          toaster.danger("الرجاء التحقق من البريد الالكتروني وكلمة المرور");
        }
      });
  }
  render() {
    return (
      <Context.Consumer>{ctx => {
        // if (ctx.value.login==='false') {
        return (
          <div style={{ height: '100vh' }}>
            <div id='mahmed'>
              <Row style={{ margin: 0, width: '90%' }} id='ahmed_row' >
                <Col lg={6} id='ahm_col2' >
                  <img src={Logo} id='logoLoginIMG' alt='logo'  />
                </Col>
                <Col xs={12} lg={6} id='ahm_col1' >
                  <div id='card_a21'  >
                    <div id='field1_ah' style={{ fontSize: '24px', fontWeight: '400', color: '#000', paddingTop: '3%' }}>تسجيل الدخول الى لوحه التحكم</div>
                    
                      <div id='field1_ah'>
                        <input autoComplete='off' type='text' placeholder=' البريد الالكتروني' id='InputTExtDash1' 
                          onChange={(e) => this.setState({ email: e.target.value })} />
                      </div>
                      <div id='field1_ah'>
                        <input autoComplete='off' type='password' placeholder=' كلمه المرور ' id='InputTExtDash1' 
                          onChange={(e) => this.setState({ password: e.target.value })} />
                      </div>
                      <div id='log1_ah'>
                        {!this.state.spinBtn ? (
                          <div id='log_ah' onClick={(e) => {
                            this.setState({ spinBtn: true })
                            this.signIN(e)
                          }}>تسجيل الدخول</div>
                        ) : (
                            <div id='log_ah' ><Spinner size={24} /></div>
                          )}
                      </div>
                    </div>
                </Col>
              </Row>
            </div>
            </div>
            )
          }
        // else if (ctx.value.login==='true') {
              //   window.location.href='/home'
              // }
              // else {
              //   return (
              //     <div style={{ width: '100%', height: 'calc(100vh - 80px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              //       <Lottie
              //         options={{
              //           animationData: animation
              //         }}
              //       />
              //     </div>
              //   )
              // }
            }
      </Context.Consumer>
        );
      }
      }
        export default Login;